import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import store from './store'
import './assets/scss/app.scss'
import { auth } from './firebase'

Vue.config.productionTip = false

Vue.config.errorHandler = (err) => {
}
let app;
  auth.onAuthStateChanged(user=> {

  
    if (!app) {
      app = new Vue({
        router,
        store,
        vuetify,
        render: h => h(App)
      }).$mount('#app')
    }
      if(user) {
        store.dispatch('fetchUserProfile', user)
      }
  })
