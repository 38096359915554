<template>
  <v-dialog max-width="600px" v-model="dialog">
    <template v-slot:activator="{ on: menu, attrss }">
      <v-btn class="greentxt ml-10 mt-2" v-bind="attrss" v-on="{ ...menu }" dark>
        <v-icon>mdi-plus-thick</v-icon>
      </v-btn>
    </template>
    <v-card class="eggwhite">
     
          <v-card-title>
            <v-text-field class="textWishlists--text font-weight-bold" v-model="wishTitle" label="Name your wishlist..">
            </v-text-field>

          </v-card-title>
      


      <v-card-text>
        <v-form ref="form">
          <div v-for="(wish, index) in wishes" :key="index">
            <v-text-field label="What do you wish for..?" class="mt-3" color="drawer" v-model="wish.value" dense
              clearable prepend-icon="mdi-gift-outline">


              <template v-slot:append>
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" icon color="error" @click="removeField(wish, wishes)">
                      <v-icon dark>mdi-sticker-remove-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>Remove field</span>
                </v-tooltip>
              </template>
            </v-text-field>
          </div>

          <v-btn icon color="success" @click="addField(wishes)">
            <v-icon dark>mdi-file-plus-outline</v-icon>
          </v-btn>
          <span>Add new line...</span>
          <br />
          <v-switch  v-model="isGroup" label="skal denne puttes i en gruppe med flere ?">

          </v-switch>
          <v-btn text class="drawer eggwhite--text mt-6" @click="createWish()" :loading="loading"
            :disabled="isDisabled">Save Wishlist</v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "createwishlist-mod",
  data() {
    return {
      wishes: [],
      wish: "",
      wishTitle:"",
      //validering for form
      inputRules: [(v) => v.length >= 3 || "Minimum length is 3 char"],
      loading: false,
      dialog: false,
      isGroup:false
    };
  },
  computed: {
    isDisabled: function () {
      return this.wishTitle=="";
    },

    wishExists: function () {
      var exist = false;
      this.wishlists.forEach((x) => {
        if (x.userName === this.userProfile.name) {
          exist = true;
        }
      });
      return exist;
    },

    ...mapState(["userProfile", "wishlists"]),
  },
  methods: {
    addField(fieldType) {
      fieldType.push({ value: "" });
    },
    removeField(index, fieldType) {
      fieldType.splice(index, 1);
    },
    
    createWish() {
      var wishlist = [];
      this.loading = true;
      if (this.$refs.form.validate()) {
        this.wishes.forEach((v) => {
          wishlist.push(v.value);
        });
        this.$store.dispatch("createWish", [wishlist, this.isGroup, this.wishTitle]);
        this.loading = false;
        this.wisharray = "";
        this.dialog = false;
        this.$emit("wishlistAdded");
      }
    },
  },
};
</script>
