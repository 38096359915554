import Vuex from 'vuex'
import Vue from 'vue'
import * as fb from '../firebase'
import router from '../router/index'
import Promise from 'core-js/features/promise'
import { deleteDoc, doc, setDoc, updateDoc, getDoc, getDocs, where, orderBy, query, onSnapshot } from 'firebase/firestore'

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    userProfile: {},
    users: [],
    posts: [],
    groups: [],
    wishlists: [],
    Errors: []
  },
  mutations: {
    setGroups(state, val) {
      state.groups = val
    },
    setUsers(state, val) {
      state.users = val
    },
    setUserProfile(state, val) {
      state.userProfile = val
    },
    setPerformingRequest(state, val) {
      state.performingRequest = val
    },
    setWishlists(state, val) {
      state.wishlists = val
    },
    setMessages(state, val) {
      state.messages = val
    }
  },
  actions: {

    async login({ dispatch }, form) {

      // sign user in
      const { user } = await fb.signInWithEmailAndPassword(fb.auth, form.email, form.password)
      return new Promise((resolve, reject) => {
        dispatch('fetchUserProfile', user)

          .catch(error => {
            reject(error.message)
          });
      })
    },

    async signup({ dispatch }, form) {
      // sign user up
      const { user } = await fb.createUserWithEmailAndPassword(fb.auth, form.email, form.password)
      const newUser = doc(fb.usersCollection, user.uid);
      await setDoc(newUser, {
        name: form.name,
        title: form.title

      });
      return new Promise((resolve, reject) => {
        // fetch user profile and set in state
        dispatch('fetchUserProfile', user)
          .catch(error => {
            reject(error.message)
          });
      })

    },
    async fetchUserProfile({ commit }, user) {
      // fetch user profile
      const docRef = getDoc(doc(fb.db, "users", user.uid));
      var userObj = (await docRef).data();
      var uidObj = { id: user.uid };
      userObj = { ...userObj, ...uidObj };
      // set user profile in state
      commit('setUserProfile', userObj)
      // change route to dashboard
      if (router.currentRoute.path === '/login') {
        router.push('/')
      }
    },


    async logout({ commit }) {
      // log user out
      await fb.auth.signOut()
      // clear user data from state
      commit('setUserProfile', {})
      // redirect to login view
      router.push('/login')
    },

    // creates a new wishlist
    async createWish({ state }, [wishes, group, name]) {
      const newWishlist = doc(fb.wishlistCollection);
      const newgroup = doc(fb.groupsCollection);
      var memberOf = [];
      var members = [];
      memberOf.push(newgroup.id)
      members.push(state.userProfile.id)
      await setDoc(newWishlist, {
        createdOn: new Date(),
        wishes,
        userId: fb.auth.currentUser.uid,
        userName: state.userProfile.name,
        comments: 0,
        memberOf:memberOf
      });
      await setDoc(newgroup, {
        name: name,
        isGroup: group,
        members: members,
        author: fb.auth.currentUser.uid,
        createdOn: new Date()

      });


    },

    // creates a message
    async createMessage({ state }, message) {
      const newMessages = doc(fb.messagesCollection);
      await setDoc(newMessages, {
        date: new Date(),
        message: message.piss,
        wishlistId: message.pass,
        userId: fb.auth.currentUser.uid,
        userName: state.userProfile.name
      })

      const q = doc(fb.wishlistCollection, message.pass);
      const querySnapshot = await getDoc(q);
      var tesss = querySnapshot.data();

      await updateDoc(q, {
        comments: parseInt(tesss.comments) + 1
      });

    },

    async updateWishes({ state }, newValue) {
      const q = query(fb.wishlistCollection, where('userId', '==', state.userProfile.id));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        var wish = doc.data();
        wish.wishes.splice(newValue.index, 1, newValue.newValue)

        updateDoc(doc.ref, {
          comments: wish.comments,
          createdOn: new Date(),
          userId: wish.userId,
          userName: wish.userName,
          wishes: wish.wishes
        });
      })
    },
    async updateGroup({ state }, groupie) {
      const q = query(fb.groupsCollection, where('id', '==', groupie.index));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        var group = doc.data();
        updateDoc(doc.ref, {
          author: group.author,
          createdOn: new Date(),
          isGroup: group.isGroup,
          name: groupie.newValue,
          members: group.members
        });
      })
    },

    async deleteWish({ state }, newValue) {
      const docRef = doc(fb.wishlistCollection, state.wishlists[0].id);
      const docSnap = await getDoc(docRef);

      var tessd = docSnap.data();
      tessd.wishes.splice(newValue.index, 1)

      updateDoc(docRef, {
        comments: tessd.comments,
        createdOn: new Date(),
        userId: tessd.userId,
        userName: tessd.userName,
        wishes: tessd.wishes
      });
    },

    //deletes wishlist
    async deleteWishlist({ state }, id) {
      var wId = `${id.id}`;
      const docRef = doc(fb.db, "wishlists", wId);
      deleteDoc(docRef);
      const q = query(fb.messagesCollection, where('wishlistId', '==', wId));

      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        doc.ref.delete();
      });

    },

    async updateProfile({ dispatch }, user) {
      const userId = fb.auth.currentUser.uid
      // update user object
      await fb.usersCollection.doc(userId).update({
        name: user.name,
        title: user.title
      })

      dispatch('fetchUserProfile', { uid: userId })

      // update all posts by user
      const postDocs = await fb.postsCollection.where('userId', '==', userId).get()
      postDocs.forEach(doc => {
        fb.postsCollection.doc(doc.id).update({
          userName: user.name
        })
      })

      // update all comments by user
      const commentDocs = await fb.commentsCollection.where('userId', '==', userId).get()
      commentDocs.forEach(doc => {
        fb.commentsCollection.doc(doc.id).update({
          userName: user.name
        })
      })
    }
  }
})

// lytter til wishlistsendringer realtime (onSnapShot)

const qwish = query(fb.wishlistCollection, orderBy('createdOn', 'desc'));
onSnapshot(qwish, (querySnapshot) => {
  let wishlistArray = []
  querySnapshot.forEach((doc) => {
    let wish = doc.data()
    wish.id = doc.id
    wish.userId = doc.data().userId
    wishlistArray.push(wish)

  })
  store.commit('setWishlists', wishlistArray)
})
const quser = query(fb.usersCollection, orderBy('name', 'desc'));
onSnapshot(quser, (querySnapshot) => {
  let userlistArray = []
  querySnapshot.forEach((doc) => {
    let user = doc.data()
    userlistArray.push(user)

  })
  store.commit('setUsers', userlistArray)
})

const qgroups = query(fb.groupsCollection);
onSnapshot(qgroups, (querySnapshot) => {
  let groupsArray = []
  querySnapshot.forEach((doc) => {
    let groupData = doc.data()
    const group = {
      id: doc.id,
      members: groupData.members,
      name: groupData.name,
      author: groupData.author,
      isGroup: groupData.isGroup,
      createdOn: groupData.createdOn
    }
    groupsArray.push(group)

  })
  store.commit('setGroups', groupsArray)
})

export default store
