<template>
  <div>
    <v-container fluid class="my-10">
      <v-container>
        <!--fluid at den strekker seg-->
        <!-- no gutters fjerner margin-->
        <v-card outlined elevation="5" class="py-3 px-3 mt-4" color="cards">

          <v-row>
            <v-col cols="6">
              <v-card-title class="textWishlists--text">My Groups</v-card-title>
            </v-col>
            <v-col cols="6"></v-col>
            <v-spacer></v-spacer>

          </v-row>


          <v-list class="eggwhite">
            <v-row class="pa-3">
              <v-col cols="3">
                <div class="font-weight-regular caption textWishlists--text text-left">Name</div>
              </v-col>
              <v-col cols="3">
                <div class="font-weight-regular caption textWishlists--text text-left">Author</div>
              </v-col>
              <v-col cols="3">
                <div class="font-weight-regular caption textWishlists--text text-left">Created</div>
              </v-col>
              <v-col cols="3">
                <div class="font-weight-regular caption textWishlists--text text-left">Grouptype</div>
              </v-col>
            </v-row>
            <template>
              <div v-for="(item, index) in groups" :key="index">
                <div v-if="findNameInMembers(item.members)">
                <v-list-item  @click="viewGroup($event, item)" class="eggwhite">
                  <template >
                    <v-row>
                      <v-col cols="3">
                        <v-list-item-title class="mt-1">{{ item.name }}</v-list-item-title>
                      </v-col>
                      <v-col cols="3">
                        <v-list-item-subtitle class="mt-1">{{ findNameInUsers(item.author) }}</v-list-item-subtitle>
                      </v-col>
                      <v-col cols="3">
                        <v-list-item-subtitle class="mt-1">{{ item.createdOn | formatDate }}</v-list-item-subtitle>
                      </v-col>
                      <v-col cols="3">
                        <v-icon v-if="item.isGroup == true" color="blue" class="mt-1">mdi-human-queue</v-icon>
                        <v-icon v-else color="green" class="mt-1">mdi-human-male</v-icon>
                      </v-col>
                    </v-row>
                  </template>
                </v-list-item>

                <v-divider v-if="index < currentGroupLength()" :key="index"></v-divider>
              </div>
              </div>
            </template>
          </v-list>

        </v-card>
      </v-container>
    </v-container>

    <transition name="fade">
      <div v-if="showGroupModal" class="p-modal">
        <div class="eggwhite p-container">
          <v-icon @click="closeGroupModal()" class="close error--text">mdi-close-circle</v-icon>
          <v-text-field v-on:change="updateGroup(fullGroup.id, $event)" class="textWishlists--text font-weight-bold" v-model="fullGroup.name" ></v-text-field>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import { mapState } from "vuex";
import moment from "moment";
export default {
  name: "members-mod",
  data() {
    return {
      showGroupModal: false
    };
  },
  computed: {
    ...mapState(["users", "userProfile", "wishlists", "groups"]),
  },
  filters: {
    formatDate(val) {
      if (!val) {
        return "-";
      }

      let date = val.toDate();
      return moment(date).format("DD/MM/YYYY");
    }
    
  },
  methods: {
    findNameInUsers(val){
      for(var i = 0; i < [...this.users].length; i++){
        if([...this.users][i].id == val){
          return [...this.users][i].name
        }
      }
      
    },
    currentGroupLength(){
      var counter = 0;
      for(var i = 0; i < [...this.groups].length; i++){
        for(var x = 0; x < [...this.groups][i].members.length; x++) {
          if([...this.groups][i].members[x] == this.userProfile.id){
            counter++;
          }
        }
      }
      return counter -1;
    },
    findNameInMembers(val){
      for(var i = 0; i < val.length; i++){
        if(val[i] == this.userProfile.id){
          return true
        }
      }
      return false
      
    },

    closeGroupModal() {
      this.showGroupModal = false;
    },
    async viewGroup(event, item) {
      this.fullGroup = item;
      this.showGroupModal = true;
    },
    async updateGroup(index, newValue) {
      this.$store.dispatch("updateGroup", {
        index,
        newValue,
      });
    },
  },
};
</script>

<style>

</style>
