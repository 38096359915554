<template>
  <v-app>
    <div id="app">
      <v-main>
        <Toolbar v-if="showNav"></Toolbar>
        <router-view></router-view>
      </v-main>
    </div>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import Toolbar from "./components/Layout/Toolbar.vue";
export default {
  name: 'App',
  
  components: {
    Toolbar,
  },
  computed: {
    ...mapState(["userProfile"]),
    showNav() {
      console.log(Object.keys(this.userProfile).length)
      return Object.keys(this.userProfile).length > 0;
    },
  },
};
</script>

<style>
html {
  overflow: hidden !important;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
#app {
  background-color: var(--v-mainbackground-base);
  /* background-image: linear-gradient(45deg, #f8cbcb 0%, #eefeff 100%); */
}
</style>
