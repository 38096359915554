<template>
  <div class="dashboard" v-if="hasWishlists.userId == userProfile.id">

    <v-btn tile class="settings" fab dark color="cog" small fixed right>
      <v-icon small dark>mdi-cog-outline</v-icon>
    </v-btn>
    <!-- <img class="santa" src="../assets/logo.png" width="750" height="300"> -->
    <!--fluid at den strekker seg-->
    <v-container fluid class="my-10">
      <!-- <h3 class="eggwhite--text font-weight-regular">Wishlists</h3>
      <v-divider dark></v-divider> -->
      <v-container>
        
        <v-row>
          <div v-for="(group, index) in groups" :key="index">
            <div v-if="findNameInMembers(group.members)">

            <v-card min-width="500" outlined elevation="5" class="py-3 mt-4 mr-10" color="cards" dark>
              <v-app-bar outlined elevation="0" class="cards">
                <v-card-title class="textWishlists--text">{{ group.name }}</v-card-title>
                <v-spacer></v-spacer>
                <v-icon v-if="group.isGroup == true" color="blue" class="mt-2">mdi-human-queue</v-icon>
                <v-icon v-else color="green" class="mt-2">mdi-human-male</v-icon>




              </v-app-bar>
              <v-row class="pa-3">
                <v-col cols="2"></v-col>
                <v-col cols="2">
                  <div class="font-weight-regular caption textWishlists--text text-left">Name</div>
                </v-col>
                <v-col cols="3">
                  <div class="font-weight-regular caption textWishlists--text text-left">
                    Changed
                  </div>
                </v-col>
                <v-col cols="3">
                  <div class="font-weight-regular caption textWishlists--text text-left">
                    Comments
                  </div>
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <div v-for="(wishlist, index) in wishlists" :key="index">
                <template v-if="
                  wishlist.memberOf.includes(group.id)
                ">
                  <v-divider light></v-divider>
                  <!-- dette representerer 12 rader-->

                  <!-- no gutters fjerner margin-->
                  <v-row @click="viewWish($event, wishlist)" class="pa-1 row-pointer">
                    <v-col cols="2">
                      <v-avatar class="ml-2" size="45" v-bind:style="
                        wishlist.userId === userProfile.id
                          ? 'border: 3px solid #4CAF50;'
                          : 'border: 3px solid #ba0e4a;'
                      ">
                        <img src="/avatar-5.png" />
                      </v-avatar>
                    </v-col>
                    <v-col cols="2">
                      <div class="mt-4  caption textWishlists--text text-left">
                        {{ wishlist.userName }}
                      </div>
                    </v-col>
                    <v-col cols="3">
                      <div class="mt-4  caption textWishlists--text text-left">
                        {{ wishlist.createdOn | formatDate }}
                      </div>
                    </v-col>
                    <v-col cols="3">
                      <div class="mt-4 ml-1 textWishlists--text caption" v-if="wishlist.userId != userProfile.id">
                        {{ wishlist.comments }}
                      </div>
                      <div v-else>
                        <v-icon color="red" class="mt-3">mdi-waze</v-icon>
                      </div>
                    </v-col>
                    <v-col cols="1">
                      <div v-if="wishlist.userId === userProfile.id">
                        <v-dialog v-model="dialog" max-width="250">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn color="error" dark large icon v-bind="attrs" v-on="on" class="mt-1">
                              <v-icon>mdi-delete</v-icon>
                            </v-btn>
                          </template>
                          <v-card class="error">
                            <v-card-title class="headline eggwhite--text">
                              Deleting wishlist..
                            </v-card-title>
                            <v-card-text class="eggwhite--text">
                              This will also delete comments Are you sure?
                            </v-card-text>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn color="eggwhite" text outlined @click="deleteWishlist(wishlist.id)">
                                Yes
                              </v-btn>
                              <v-btn color="eggwhite" class="ml-16" outlined text @click="closeDialog()">
                                No
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                      </div>
                    </v-col>
                  </v-row>
                  <v-divider></v-divider>
                </template>
              </div>


            </v-card>

          </div>
          </div>
        </v-row>

      </v-container>

      <!-- cols for small screen, md for big screens-->
      <!-- justify space around sier at den skal putte slikt at det blir synkront" -->
    </v-container>
    <transition name="fade">
      <div v-if="showWishModal" class="p-modal">
        <div class="eggwhite p-container">
          <v-icon @click="closeWishModal()" class="close error--text">mdi-close-circle</v-icon>

          <h5>{{ thisWishlist.userName }} 's wishlist</h5>
          <div v-for="(wishes, index) in thisWishlist.wishes" :key="index">
            <v-text-field v-on:change="updateWishes(index, $event)" :value="wishes" dense clearable
              :disabled="thisWishlist.userId != userProfile.id" prepend-icon="mdi-gift-outline">
              <template v-slot:append>
                <v-btn icon color="error" v-if="thisWishlist.userId == userProfile.id"
                  @click="removeField(index, thisWishlist.wishes)">
                  <v-icon v-if="thisWishlist.userId == userProfile.id" dark>mdi-sticker-remove-outline</v-icon>
                </v-btn>
              </template>
            </v-text-field>
          </div>
          <v-btn v-if="thisWishlist.userId == userProfile.id" icon color="success"
            @click="addField(thisWishlist.wishes)">
            <v-icon dark v-if="thisWishlist.userId == userProfile.id">mdi-file-plus-outline</v-icon>
          </v-btn>
          <span v-if="thisWishlist.userId == userProfile.id">Add new line...</span>
          <div v-if="thisWishlist.userId != userProfile.id">
            <Chat v-bind:wishlistId="thisWishlist.id" />
          </div>
        </div>
      </div>
    </transition>
  </div>

  <div v-else class="ml-16">
    <div class="bigtext textWishlists--text">
      There's nothing here... yet :(
    </div>
    <div class="smalltext textWishlists--text">
      ... try using the
      <span class="greentext font-weight-bold greentxt--text">green ＋</span> button
    </div>
  </div>

</template>

<script>

import { mapState } from "vuex";
import moment from "moment";
import Chat from "../components/Chat.vue";
export default {
  name: "dashboard-mod",

  components: { Chat },
  data() {
    return {
      dialog: false,
      showWishModal: false,
      fullWishlist: {}
    };
  },


  computed: {
    ...mapState(["userProfile", "wishlists", "users", "groups"]),
    hasWishlists() {
      var returnvalue = [];
      this.wishlists.forEach(element => {

        if (element.userId == this.userProfile.id) {

          returnvalue = element;
        }
      })
      return returnvalue;
    }

  },
  methods: {

    addField(fieldType) {
      fieldType.push("");
    },
    removeField(index, fieldType) {
      //type.splice(index, 1);
      var newValue = null;
      fieldType.splice(index, 1);
      this.$store.dispatch("deleteWish", {
        index,
        newValue,
      });
    },
    closeDialog() {
      this.dialog = false;
    },
    findNameInMembers(val){
      for(var i = 0; i < val.length; i++){
        if(val[i] == this.userProfile.id){
          return true
        }
      }
      return false
      
    },
    closeWishModal() {
      this.showWishModal = false;
    },
    async deleteWishlist(id) {
      this.$store.dispatch("deleteWishlist", {
        id: id
      });
      this.dialog = false;
    },

    async updateWishes(index, newValue) {
      this.$store.dispatch("updateWishes", {
        index,
        newValue,
      });
    },

    async viewWish(event, wishlist) {
      this.thisWishlist = wishlist;
      this.showWishModal = true;
    },
  },
  filters: {
    formatDate(val) {
      if (!val) {
        return "-";
      }

      let date = val.toDate();
      return moment(date).fromNow();
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Amatic+SC:wght@400;700&display=swap");

.bigtext {
  margin-top: 1em;
  font-family: "Amatic SC", cursive;
  font-size: 109px;
}

.smalltext {
  font-family: "Amatic SC", cursive;
  font-size: 30px;
}

.greentext {
  font-family: "Amatic SC", cursive;
  font-size: 30px;
  font-weight: 1000;
}

.row-pointer:hover {
  cursor: pointer;
  opacity: 0.6;
}

.settings {
  border: none;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;

}
</style>
