<template>
  <nav>
    <!-- Dette er en snackbar som popper opp, burde lage en egen snackbar comp etterhvert -->
    <v-snackbar app v-model="addedWish" timeout="3000" shaped right top color="snackbar">
      Awesome! You added a wishlist. <br />
      Go to your group and add friends
      <v-btn text class="eggwhite--text ml-5" @click="addedWish = false">
        Close
      </v-btn>
    </v-snackbar>
    <v-snackbar app v-model="userExists" timeout="3000" shaped right top color="snackbar">
      Sorry, you allready made a wishlist
      <v-btn text class="eggwhite--text ml-5" @click="userExists = false">
        Close
      </v-btn>
    </v-snackbar>

    <!-- Blue top toolbar -->
    <div class="toolbar-border">
      <v-app-bar flat app class="toolbar">
        <v-app-bar-nav-icon @click="drawer = !drawer" color="drawericon"></v-app-bar-nav-icon>
        <span>

          <router-link to="/"><img class="smallsanta" src="../../assets/santa.png" width="55"
              height="55" /></router-link>



        </span>


        <!--Create wishlist component-->
        <Createwishlist @wishlistAdded="addedWish = true" @wishlistNotAdded="userExists = true" />
        <v-spacer></v-spacer>
        <!-- MENU Dropdown -->
        <div class="text-center">
          <v-menu v-model="menu" :close-on-content-click="false" :nudge-width="200" offset-x>
            <template v-slot:activator="{ on, attrs }">
              <v-badge color="cog" :content=2 :value=2 overlap>
                <v-avatar dark v-bind="attrs" v-on="on" class="ml-2 profilebuttonIcon" size="45">
                  <div class="profileLetter--text font-weight-black">
                    {{ userProfile.name.charAt(0).toUpperCase() }}
                  </div>
                </v-avatar>
              </v-badge>
            </template>

            <v-card>
              <v-list>
                <v-list-item>
                  <v-avatar dark class="profilebuttonIcon" size="45">
                    <div class="profileLetter--text font-weight-bold">
                      {{ userProfile.name.charAt(0).toUpperCase() }}
                    </div>
                  </v-avatar>

                  <v-list-item-content>
                    <v-list-item-title class="ml-2">{{
                      userProfile.name
                    }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>

              <v-divider></v-divider>

              <v-list>
                <v-list-item>
                  <v-list-item-action>
                    <v-switch color="purple" @change="darkMode"></v-switch>
                  </v-list-item-action>
                  <v-list-item-title>Enable darkmode</v-list-item-title>
                </v-list-item>
              </v-list>

              <v-card-actions>
                <v-btn color="snackbar" text @click="menu = false"> Close </v-btn>
                <v-spacer></v-spacer>
                <v-btn :class="fav ? 'error--text' : ''" icon @click="fav = !fav">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" @click="logout()" color="error">mdi-exit-to-app</v-icon>
                    </template>
                    <span>Logout</span>
                  </v-tooltip>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
        </div>

      </v-app-bar>
    </div>

    <!-- Green navigation drawer  :mini-variant="mini" -->
    <v-navigation-drawer fixed app hide-overlay dark class="drawer" v-model="drawer">
      <v-toolbar-title class="text-uppercase white--text mt-3 ml-3">
        <span class="font-weight-thin eggwhite--text">SecretSanta Admin</span>
        <p class="font-weight-thin text-lowercase eggwhite--text caption">
          v 0.0.1
        </p>
      </v-toolbar-title>

      <v-list nav dense>
        <v-list-item link v-for="link in links" :key="link.text" router :to="link.route">
          <v-list-item-action>
            <v-icon class="eggwhite--text">{{ link.icon }}</v-icon>
            <!-- forløkke objekt syntax -->
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title class="eggwhite--text">{{
              link.text
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <template v-slot:append>
        <v-list>
          <v-list-item>
            <v-icon class="ml-16" dark>mdi-facebook</v-icon>
            <v-icon class="ml-2" dark>mdi-instagram</v-icon>
            <v-icon class="ml-2" dark> mdi-snapchat</v-icon>
          </v-list-item>
        </v-list>
        <div link text-align="center" class="caption text--sheer text-center eggwhite--text font-weight-light">
          © 2023 Secretsanta by Codehaak
        </div>
      </template>
    </v-navigation-drawer>
  </nav>
</template> 

<script>
import Createwishlist from "../Createwishlist.vue";
import { mapState } from "vuex";
export default {
  components: { Createwishlist },
  name: "Toolbar-mod",
  data() {
    return {
      links: [
        { icon: "mdi-gift-outline", text: "Wishlists", route: "/" },

        { icon: "mdi-human-queue", text: "Groups", route: "/members" },
      ],
      addedWish: false,
      userExists: false,
      dialog: false,
      notifications: false,
      sound: true,
      widgets: false,
      fav: true,
      menu: false,
      message: false,
      hints: true,
      drawer: true
    };
  },
  computed: {
    ...mapState(["userProfile", "groups"]),
    mini() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        case "md":
          return true;
        case "lg":
          return false;
        case "xl":
          return false;
        default:
          return false;
      }
    },
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
    },
    darkMode() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
    },
  },
};
</script>
<style scoped>
.image-upload>input {
  display: none;
  cursor: pointer;
}

.toolbar-border {
  border-bottom: 4px solid;
  color: var(--v-toolbarbottom-base);
}
</style>