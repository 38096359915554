import Vue from 'vue'
import VueRouter from 'vue-router'
import Dashboard from '../views/Dashboard.vue'
import Members from '../views/Members.vue'
import { auth } from '../firebase'

Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/members',
    name: 'Members',
    component: Members,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import( /* webpackChunkName: "login" */ '../views/Login.vue'),
    meta: { requiresUnauth: true }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  user: auth
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  const requiresUnauth = to.matched.some(record => record.meta.requiresUnauth)

  if (!auth.currentUser && requiresAuth) 
    next({ name: 'Login' })
  if (requiresUnauth && auth.currentUser)
    next({ name: 'Dashboard' })
  else next()
})

export default router;

