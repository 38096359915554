import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
      iconfont: 'mdi'
  
    },
  // this is the spot to modify
  
    theme: {
      options: {
        customProperties: true,
      },
      themes: {
        light: {
          sheer: '#f1eff0',
          textWishlists:'#ba0e4a',
          textMembers: '#ba0e4a',
          toolbar:'#f1eff0',
          toolbarbottom:'#ba0e4a',
          profilebuttonIcon:'#ba0e4a',
          profileLetter: '#f1eff0',
          drawer:'#ba0e4a',
          drawericon:'#ba0e4a',
          primary: '#1976D2',
          error: '#ba0e4a',
          info: '#2196F3',
          greentxt: '#4CAF50',
          success: '#4CAF50',
          warning: '#FFC107',
          cards: '#f1eff0',
          eggwhite: '#f1eff0',
          snackbar: '#ba0e4a',
          cog:'#EE52B0',
          mainbackground:'#f1eff0'
         
        },
        dark: {
          primary: '#9652ff',
          toolbar: '#212133',
          drawer: '#e55d64',
          cards: '#27293D',
          sheer: '#f1eff0',
          mainbackground:'#212133',
          toolbarbottom:'#212133',
        }
      }
    }
  })

//   background-image: linear-gradient(45deg,#ccd1ff 0%, #eefeff 100%); background color