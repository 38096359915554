import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged, signInWithEmailAndPassword, createUserWithEmailAndPassword, GoogleAuthProvider } from "firebase/auth";
import { collection, getFirestore} from "firebase/firestore"; 

// firebase init
const firebaseConfig = {
    apiKey: "AIzaSyCn7oZuXLtF9m5nPW4G-X370NbjJzl3gYs",
    authDomain: "precortx.firebaseapp.com",
    projectId: "precortx",
    storageBucket: "precortx.appspot.com",
    messagingSenderId: "422491006046",
    appId: "1:422491006046:web:bb390c703e83a4bda780cf",
    measurementId: "G-Z0XF1RG8PG"
}


// utils
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);

// collection references
const usersCollection     = collection(db,'users')
const postsCollection     = collection(db,'posts')
const commentsCollection  = collection(db,'comments')
const likesCollection     = collection(db,'likes')
const wishlistCollection  = collection(db,'wishlists')
const messagesCollection  = collection(db,'messages')
const groupsCollection = collection(db, 'groups')


// export utils/refs
export {
  GoogleAuthProvider,
  db,
  auth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  usersCollection,
  postsCollection,
  commentsCollection,
  likesCollection,
  wishlistCollection,
  messagesCollection,
  groupsCollection
}
