<template>
  <div class="message-body mt-3">
    <h3>Chat</h3>
    <div class="card">
      <div class="card-body">
        <input
          v-on:keyup.enter="sendMessage"
          v-model="showMessage"
          type="text"
          class="mt-3 ml-4 pl-2 pr-2"
        />
        <v-btn text>
          <v-icon color="primary" @click="sendMessage">mdi-send-outline</v-icon>
        </v-btn>
        <div v-for="message in chat" :key="message.id">
          <v-card flat class="eggwhite mt-5">
            <v-row
              v-bind:style="
                message.username === userProfile.name
                  ? 'border-left: 4px solid #76FF03;'
                  : 'border-right: 4px solid #D81B60; text-align: right;'
              "
            >
              <v-col>
                <span class="primary--text">{{ message.username }}</span>
                <span class="font-weight-thin caption text-left ml-5">{{
                  message.date | formatDate
                }}</span>
                <p class="message pt-1">{{ message.text }}</p>
              </v-col>
            </v-row>
          </v-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { messagesCollection, } from "@/firebase";
import { mapState } from "vuex";
import { deleteDoc, doc, setDoc, updateDoc, getDoc, getDocs, where, orderBy, query, onSnapshot } from 'firebase/firestore'
import moment from "moment";
moment.locale("no");
export default {
  name: "chat-mod",
  props: {
    wishlistId: {
      type: String,
    },
  },
  data() {
    return {
      userName: "",
      name: null,
      showMessage: "",
      chat: [],
      viewChat: [],
    };
  },
  computed: {
    ...mapState(["userProfile"]),
  },
  methods: {
    updateUsername() {
      this.name = this.userProfile.name;
      this.userName = "";
    },
    sendMessage() {
      const packdage = {
        piss: this.showMessage,
        pass: this.wishlistId,
      };
      this.$store.dispatch("createMessage", packdage);
      this.showMessage = "";
    },
  },

  mounted() {
    const qwish = query(messagesCollection, where("wishlistId", "==", this.wishlistId), orderBy("date", "desc"));
    onSnapshot(qwish, (querySnapshot) => {
      this.chat = [];
      querySnapshot.forEach((doc) => {
        this.chat.push({
          id: doc.id,
          username: doc.data().userName,
          date: doc.data().date,
          text: doc.data().message,
        });
      });
      this.viewChat = this.chat;
    })



  },
  filters: {
    formatDate(val) {
      if (!val) {
        return "-";
      }
      let date = val.toDate();
      return moment(date).calendar();
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,400italic|Material+Icons");

h3 {
  font-size: 30px;
  text-align: center;
}
input {
  width: 100%;
  border-radius: 4px;
  border: 1px solid rgb(156, 156, 156);
  padding-left: 2px;
  padding-right: 2px;
}
.message-body {
  width: auto;
  height: auto;
  margin: auto;
}
.message-text {
  min-width: 10%;
  border-radius: 4px;
}
.message {
  font-size: 14px;
}
.mg-text {
  color: rgb(0, 195, 255);
  font-weight: bolder;
}
.message-body input {
  width: 80%;
  border-radius: 4px;
  border: 1px solid rgb(156, 156, 156);
  height: 6vh;
  padding-left: 2px;
  padding-right: 2px;
}
.card {
  width: 100%;
  margin: auto;
}
.card-body {
  min-height: 50vh;
}
</style>